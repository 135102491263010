import React from 'react';
import PropTypes from 'prop-types';
import { moveInTerm } from 'helpers/util';
import MessageModal from 'components/Message/MessageModal';

// Props:
// --------------------------------------------------
// onSubmit: Callback Function on Submit (Required)
// Property: Property Being Display (Required)

const ConfirmListing = props => {
  const {
    onSubmit,
    property,
  } = props;

  return (
    <>
      <h5 className="apply-steps">Confirm Application</h5>
      <h1>Is everything below correct?</h1>
      <div className="listing-table mx-auto">
        <div className="row no-gutters">
          <div className="col-md-6">
            <img
              alt="listing"
              className="img-fluid listing-img mx-auto"
              src={property.image.wide}
            />
          </div>
          <div className="col-md-6 listing-card-dark-kv-row">
            <div className="listing-card-dark-kv-cell">
              <b>Rent</b>
              {accounting.formatMoney(property.price, { precision: 0 })}
            </div>
            <div className="listing-card-dark-kv-cell">
              <b>Move-in Cost</b>
              {accounting.formatMoney(
                (property.price * property.moveInCost.length),
                { precision: 0 },
              )}
            </div>
            <div className="listing-card-dark-kv-cell">
              <b>Move-in Terms</b>
              {moveInTerm(property.moveInCost)}
            </div>
            <div className="listing-card-dark-kv-cell">
              <b>Utilities</b>
              {property.utilities}
            </div>
            <div className="listing-card-dark-kv-cell">
              <b>Pets</b>
              {property.pets}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="listing-card-dark-kv-row">
                <div className="listing-card-dark-kv-cell">
                  <i className="nookons-marker mr-2" />
                  {' '}
                  {property.shortAddress}
                </div>
              </div>
              <div className="row listing-card-dark-kv-row">
                <div className="col-8 listing-card-dark-kv-cell border-right">
                  <i className="nookons-buildings mr-2" />
                  {property.neighborhood.name}
                </div>
                <div className="col-4 listing-card-dark-kv-cell">
                  {property.subwayLines
                    && property.subwayLines
                      .map((subwayStop, idx) => (
                        <img
                          key={idx}
                          alt={subwayStop}
                          id="subway-img"
                          src={`${require(`images/subway/2x/${subwayStop}.png`)}`}
                        />
                      ))}
                </div>
              </div>
              <div className="row listing-card-dark-kv-row">
                <div className="col-6 listing-card-dark-kv-cell border-right border-bottom-none">
                  <i className="nookons-bed mr-2" />
                  {property.bedrooms}
                  {' '}
                  Bed
                </div>
                <div className="col-6 listing-card-dark-kv-cell border-bottom-none">
                  <i className="nookons-bath mr-2" />
                  {property.bathrooms}
                  {' '}
                  Bath
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters">
              <div className="col-6 listing-card-dark-img">
                <img
                  alt="listing"
                  className="img-more img-fluid mx-auto"
                  src={property.images[1].wide}
                />
              </div>
              <div className="col-6 listing-card-dark-img">
                <img
                  alt="listing"
                  className="img-more img-fluid mx-auto"
                  src={property.images[2].wide}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="button btn-rounded btn-next"
        onClick={onSubmit}
      >
        Yes
      </button>
      <MessageModal
        recipient_id={property.contact.recipientId}
        redirectPath={`/listings/${property.slug}`}
      >
        <div className="btn-no">
          No, I need to
          {' '}
          <b>update something</b>
          .
        </div>
      </MessageModal>
    </>
  );
};

ConfirmListing.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  property: PropTypes.shape({
    bathrooms: PropTypes.number,
    bedrooms: PropTypes.number,
    contact: PropTypes.shape({
      recipientId: PropTypes.number,
    }),
    id: PropTypes.number,
    image: PropTypes.shape({
      wide: PropTypes.string,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      wide: PropTypes.string,
    })),
    moveInCost: PropTypes.arrayOf(PropTypes.string),
    neighborhood: PropTypes.shape({
      name: PropTypes.string,
    }),
    pets: PropTypes.string,
    price: PropTypes.number,
    salesAgent: PropTypes.shape({
      id: PropTypes.number,
    }),
    shortAddress: PropTypes.string,
    slug: PropTypes.string,
    subwayLines: PropTypes.arrayOf(PropTypes.string),
    utilities: PropTypes.string,
  }).isRequired,
};

export default ConfirmListing;
