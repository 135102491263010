import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RequestTourFooter from 'components/renterForms/RequestTourFooter';
import { checkEmail } from 'helpers/util';

// Props:
// --------------------------------------------------
// intialEmail: Intial Email for Form Step (Optional) (Default: '')
// onSubmit: Callback Function on Submit (Required)
// shortAddress: Address to display on bottom (Required)

const EmailCollection = props => {
  const {
    initialEmail,
    onSubmit,
    shortAddress,
  } = props;

  const [email, setEmail] = useState(initialEmail);
  const [disabledContinue, setDisabledContinue] = useState(true);

  useEffect(() => {
    if (checkEmail(email)) {
      setDisabledContinue(false);
    } else {
      setDisabledContinue(true);
    }
  }, [email]);

  const submitEmailForm = () => {
    if (checkEmail(email)) {
      onSubmit(email);
    } else {
      setDisabledContinue(true);
    }
  };

  return (
    <>
      <h3>What&apos;s your email address?</h3>
      <div className="tour-notes">An agent will email you shortly to confirm</div>
      <div className="input-group mt-3">
        <div className="input-group-prepend">
          <span className="input-group-text general-text-field-dark br-0" style={{ height: '45px', width: '45px' }}>
            <i className="nookons-email mx-auto" />
          </span>
        </div>
        <input
          type="email"
          name="email"
          className="general-text-field-dark form-control"
          style={{ height: '45px', color: '#fff' }}
          value={email}
          onChange={evt => setEmail(evt.target.value)}
          autoComplete="email"
        />
      </div>
      <RequestTourFooter
        shortAddress={shortAddress}
        handleClick={() => { submitEmailForm(); }}
        disabled={disabledContinue}
      />
    </>
  );
};

EmailCollection.propTypes = {
  initialEmail: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  shortAddress: PropTypes.string.isRequired,
};

EmailCollection.defaultProps = {
  initialEmail: '',
};

export default EmailCollection;
