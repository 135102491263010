import React, { useRef } from 'react';
import Popover from 'components/generic/Popover';

const ConversationWarning = () => {
  const targetRef = useRef(null);

  // Special Notes:
  // Negative Margin in Popover is dealing with some issues with rendering
  // and bounding box calculations with ruby and react hybrid area

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '5px',
          color: '#ffc03a',
          cursor: 'help',
        }}
        ref={targetRef}
      >
        <span>This is not a Nooklyn Agent</span>
        <i className="nookons-exclamation-circle-fill" id="nklyn-listing-tour-img" style={{ lineHeight: '1.5', fontWeight: '500', marginLeft: '6px' }} />
      </div>
      <Popover
        type="hover"
        targetRef={targetRef}
        style={{
          width: '325px',
          backgroundColor: '#111111',
          textAlign: 'left',
          color: 'white',
          border: 'rgb(255, 192, 58) solid 1px',
          padding: '10px',
        }}
      >
        <p>Heads Up! This is a listing from a user on Nooklyn. We've tried our best to vet and review this listing but please use your best judgement, especially before sending funds.</p>
      </Popover>
    </>
  );
};

export default ConversationWarning;
