import React from 'react';
import PropTypes from 'prop-types';

const Success = props => {
  const {
    conversationId,
    conversationInviteId,
    signedIn,
    onBack,
  } = props;

  const handleOpenMessages = evt => {
    evt.preventDefault();
    if (signedIn) {
      window.location = `/conversations/${conversationId}`;
    } else if (conversationInviteId) {
      window.location = `/conversation_invites/${conversationInviteId}`;
    }
  };

  return (
    <>
      <div className="tour-success">
        <i className="nookons-check-circle" />
        <div className="tour-success-header">Your request was successfully received.</div>
        <div className="tour-notes" style={{ fontSize: '16px' }}>We will email you soon with instructions on how to proceed.</div>
        <div className="d-flex m-5">
          {(onBack !== undefined) && (
            <div className="button btn-transparent-yellow btn-rounded text-left btn-request mr-3" onClick={onBack}>
              <span>Go back</span>
              <i className="nookons-reply ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
            </div>
          )}
          {(conversationId !== undefined && conversationInviteId !== undefined) && (
            <div className={`button btn-transparent btn-rounded text-left btn-request ${(signedIn || conversationInviteId) ? '' : 'disabled'}`} onClick={handleOpenMessages}>
              <span>Open messages</span>
              <i className="nookons-messages ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Success.propTypes = {
  conversationId: PropTypes.string,
  conversationInviteId: PropTypes.string,
  signedIn: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
};

Success.defaultProps = {
  conversationId: undefined,
  conversationInviteId: undefined,
  onBack: undefined,
};

export default Success;
