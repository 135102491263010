import React, { useState, useEffect } from 'react';
import strftime from 'strftime';
import PropTypes from 'prop-types';
import ContainerWrapper from 'components/lease/ContainerWrapper';
import loggedIn from 'helpers/loggedIn';
import { getParamsFromLink } from 'helpers/util';

import TourTypeSelector from './steps/TourTypeSelector';
import EmailCollection from './steps/EmailCollection';
import DateSelection from './steps/DateSelection';
import CaptchaConfirmation from './steps/CaptchaConfirmation';
import Warning from './steps/Warning';
import Success from './steps/Success';
import fetchListing, { handleSendMessage, handleSetupLead } from './api';

// Props:
// --------------------------------------------------
// acceptApply: Whether current listing has nooklyn quick apply (Required)
// property: Property object (Required for Buildings) (Listings only need to pass id)
// ^^  [Above can be Refactored with creation of building api call] ^^
// shortAddress: Address to display on bottom of tour screen (Required)
// type: Type of Property being toured can only be [listings, buildings] (Required)

const ScheduleTour = props => {
  const {
    acceptApply,
    property,
    shortAddress,
    type,
  } = props;

  // States for async loading
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [referringAgent, setReferringAgent] = useState(undefined);
  const [touringProperty, setTouringProperty] = useState(undefined);

  // States for form information collected
  const [tourType, setTourType] = useState('');
  const [email, setEmail] = useState('');
  const [schedule, setSchedule] = useState(undefined);
  const [captchaResponse, setCaptchaResponse] = useState(undefined);
  const [warningResponse, setWarningResponse] = useState(undefined);
  const [conversationId, setConversationId] = useState('');
  const [conversationInviteId, setConversationInviteId] = useState('');

  // States for form progression
  const [pageNumber, setPageNumber] = useState(1);
  const [successStep, setSuccessStep] = useState(false);

  const TOTAL_STEPS = 5;
  const types = ['listings', 'buildings'];

  // Checks for Property Typing and then sets TouringProperty
  useEffect(() => {
    if (type && types.includes(type)) {
      if (type === 'listings') {
        fetchListing(property.id).then(res => {
          setTouringProperty(res);
        });
      } else {
        setTouringProperty(property);
      }
    } else {
      throw new Error('Property Type Error');
    }
  }, [type]);

  // Once Touring Property is set, stops page loading
  useEffect(() => {
    if (touringProperty) {
      loggedIn()
        .then(res => {
          setSignedIn(res);
          setLoading(false);
        });
    } else {
      setLoading(true);
    }
  }, [touringProperty]);

  useEffect(() => {
    if (touringProperty) {
      if (type === 'listings') {
        if (touringProperty.contact.contactType !== 'message') {
          const params = getParamsFromLink();
          if (params['sales-agent'] !== undefined) {
            setReferringAgent(params['sales-agent']);
          }
        }
      }
    }
  }, [type, touringProperty]);

  // Handles Google Ads Event Firing
  function gtagReportConversion(url) {
    const callback = () => {
      if (typeof (url) !== 'undefined') {
        window.location = url;
      }
    };
    // eslint-disable-next-line no-undef
    gtag(
      'event', 'malaka', {
        send_to: 'AW-968054781/qt-ECOmgjIACEP2vzc0D',
        value: 1.0,
        currency: 'USD',
        event_callback: callback,
      },
    );
    return false;
  }

  // Handles tour form submission
  // For listings with contactType === 'message', messages instead of leads api
  // For other listings uses leads api
  const handleSubmit = () => {
    const tourTypeToString = (tourType === 2) ? 'virtual' : 'in_person';
    const dateNotes = `${schedule.date ? `Preferred date: ${strftime('%B %e', schedule.date)}. ` : ''}`;
    const timeNotes = `${schedule.time ? `Preferred time: ${schedule.time}.` : ''}`;
    const notes = `${dateNotes}${timeNotes}`;
    const emailForMessage = signedIn ? '' : email;

    if (
      type === 'listings'
      && touringProperty.contact.contactType === 'message'
    ) {
      const message = `Hello, I am interested in seeing ${shortAddress}! If possible, I would like to see it on ${strftime('%B %e', schedule.date)} during the ${schedule.time}.`;
      const recipientId = (touringProperty.contact.recipientId).toString();

      handleSendMessage(message, recipientId, emailForMessage, captchaResponse).then(res => {
        if (res.conversation) setConversationId(res.conversation.id);
        if (res.conversation_invite_token) {
          setConversationInviteId(res.conversation_invite_token);
        }
        gtagReportConversion();
        setSuccessStep(true);
      }).catch(err => {
        throw new Error(err);
      });
    } else {
      handleSetupLead(
        type,
        touringProperty.id,
        tourTypeToString,
        notes,
        emailForMessage,
        referringAgent,
      ).then(res => {
        if (res.data.lead) setConversationId(res.data.lead.conversation_id);
        if (res.data.conversation_invite_token) {
          setConversationInviteId(res.data.conversation_invite_token);
        }
        gtagReportConversion();
        setSuccessStep(true);
      }).catch(err => {
        throw new Error(err);
      });
    }
  };

  const handleApply = () => {
    if (type === 'listings') {
      window.location = `/listings/${touringProperty.id}/apply?sales-agent=${referringAgent}`;
    }
  };

  const handleRenterGuide = () => {
    window.location = '/guides/renter';
  };

  const handleOnBack = () => {
    window.location = `/${type}/${touringProperty.slug}`;
  };

  const handlePagination = movement => {
    if ((pageNumber + movement) < 1) {
      setPageNumber(1);
    } else if ((pageNumber + movement) > TOTAL_STEPS) {
      setPageNumber(TOTAL_STEPS);
    } else {
      setPageNumber(pageNumber + movement);
    }
  };

  const handleTourTypeContinue = incomingTourType => {
    setTourType(incomingTourType);
    if (!signedIn) {
      handlePagination(1);
    } else {
      handlePagination(2);
    }
  };

  const handleEmailContinue = incomingEmail => {
    setEmail(incomingEmail);
    handlePagination(1);
  };

  const handleDateContinue = (selectedDate, selectedTime) => {
    setSchedule({
      date: selectedDate,
      time: selectedTime,
    });
  };

  const handleCaptchaConfirmation = passedCaptchaResponse => {
    setCaptchaResponse(passedCaptchaResponse);
    handlePagination(1);
  };

  const handleWarningConfirmation = passedWarningResponse => {
    setWarningResponse(passedWarningResponse);
  };

  // Handles Submit
  useEffect(() => {
    if (touringProperty) {
      if (type === 'listings') {
        if (
          (touringProperty.contact.contactType !== 'message' && schedule !== undefined)
          || (touringProperty.contact.contactType === 'message' && warningResponse !== undefined)
        ) {
          handleSubmit();
        } else {
          handlePagination(1);
        }
      } else if (schedule !== undefined) {
        handleSubmit();
      }
    }
  }, [schedule, captchaResponse, warningResponse]);

  return (
    <ContainerWrapper entered={!loading} className="request-tour">
      <>
        {(!successStep) && (
          <>
            <h1>Request a tour</h1>
            <div div className="tour-uc-notes">
              Step
              {' '}
              {pageNumber}
              /
              {TOTAL_STEPS}
            </div>
          </>
        )}
        {
          (pageNumber === 1 && !successStep) && (
            <TourTypeSelector
              acceptApply={acceptApply}
              handleApply={handleApply}
              initialOption={(signedIn ? 1 : 0)}
              shortAddress={shortAddress}
              onSubmit={handleTourTypeContinue}
            />
          )
        }
        {
          (pageNumber === 2 && !successStep) && (
            <EmailCollection
              intiialEmail={email}
              shortAddress={shortAddress}
              onSubmit={handleEmailContinue}
            />
          )
        }
        { (pageNumber === 3 && !successStep) && (
          <DateSelection
            initialDate={undefined}
            initialTime={undefined}
            shortAddress={shortAddress}
            onSubmit={handleDateContinue}
          />
        )}
        { (pageNumber === 4 && !successStep) && (
          <CaptchaConfirmation
            onSubmit={handleCaptchaConfirmation}
          />
        )}
        { (pageNumber === 5 && !successStep) && (
          <Warning
            onSubmit={handleWarningConfirmation}
          />
        )}
        { successStep && (
          <>
            <Success
              conversationId={conversationId}
              conversationInviteId={conversationInviteId}
              signedIn={signedIn}
              onBack={handleOnBack}
            />
            <div className="request-tour-footer border-0 text-center">
              <div className="tour-notes pb-3">Not sure what to expect when renting an apartment?</div>
              <div
                className="renter-guide-footer"
                role="button"
                tabIndex={0}
                onKeyDown={evt => {
                  if (evt.key === 'Enter') {
                    handleRenterGuide();
                  }
                }}
                onClick={handleRenterGuide}
              >
                <span className="pr-1 align-middle" style={{ fontWeight: '500' }}>Read the Renter&apos;s Guide</span>
                <i className="nookons-chevron-right align-middle" style={{ fontWeight: '500' }} />
              </div>
            </div>
          </>
        )}
      </>
    </ContainerWrapper>
  );
};

ScheduleTour.propTypes = {
  acceptApply: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    contact: PropTypes.shape({
      recipientId: PropTypes.number,
      contactType: PropTypes.string,
    }),
    id: PropTypes.number,
    slug: PropTypes.string,
  }),
  shortAddress: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

ScheduleTour.defaultProps = {
  property: {
    contact: {
      recipientId: -1,
      contactType: '',
    },
    id: -1,
    slug: '',
  },
};

export default ScheduleTour;
