/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import GoogleMapReact from 'google-map-react'

import clsx from 'clsx'
import { setStore } from 'reducers/search'

import LoadingSpinner from 'components/LoadingSpinner'

import s from './Map.module.css'

const MAP_TOKEN = process.env.GOOGLE_MAPS_KEY

const Map = forwardRef(({ className, children, containerChildren, onLoaded, mapProps }, ref) => {
  const mapDefaultProps = {
    center: {
      lat: 37.8,
      lng: -122.4,
    },
    zoom: 8,
  }

  const mapRef = useRef(null)

  const [isLoaded, setIsLoaded] = useState(false)

  const dispatch = useDispatch()
  const handleSetStore = useCallback((payload) => dispatch(setStore(payload)), [dispatch])

  const handleApiLoaded = (map) => {
    setIsLoaded(true)
    if (onLoaded) {
      onLoaded(map)
    }

    mapRef.current = map
  }

  const handleChangeViewState = (data) => {
    const bounds = data?.bounds

    handleSetStore({
      top_left: [bounds?.ne?.lat, bounds?.sw?.lng],
      bottom_right: [bounds?.sw?.lat, bounds?.ne?.lng],
    })
  }

  return (
    <div className={clsx(s.container, className)}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: MAP_TOKEN,
        }}
        options={{ clickableIcons: false, gestureHandling: 'cooperative' }}
        layerTypes={['TransitLayer']}
        defaultCenter={mapDefaultProps.center}
        defaultZoom={mapDefaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onChange={(data) => handleChangeViewState(data)}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        {...mapProps}
      >
        {children}
      </GoogleMapReact>
      {containerChildren}
      {!isLoaded && <LoadingSpinner style={{ top: '37%', left: '43%', position: 'absolute' }} />}
    </div>
  )
})

export default Map

Map.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  containerChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onLoaded: PropTypes.func,
  mapProps: PropTypes.shape({}),
}

Map.defaultProps = {
  className: undefined,
  children: null,
  containerChildren: null,
  onLoaded: null,
  mapProps: {},
}
