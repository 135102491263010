// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Recaptcha from 'react-google-recaptcha';
import Modal from '../modal/Modal';
import FlashMessage from '../FlashMessage';
import { submitForm, submitted } from '../../reducers/form';
import { getMetaContent } from '../../helpers/util';

class MessageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      errors: '',
      characters: 500,
      created: false,
      recaptchaChecked: false,
    };
    this.recaptchaRef = React.createRef();
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    const {
      signedIn, recipient_id: recipientId, redirectPath, contextUrl: context_url,
    } = this.props;
    const { message } = this.state;
    const recipient_id = recipientId.toString();
    const email = signedIn ? '' : evt.target.email.value;
    const url = signedIn ? '/conversations.json' : '/conversation_invites.json';
    const params = signedIn ? {
      conversation: { message, recipient_id, context_url, email },
      authenticity_token: getMetaContent('csrf-token'),
    } : {
      conversation_invite: { message, recipient_id, context_url, email },
      'g-recaptcha-response': this.recaptchaRef.current.getValue(),
      authenticity_token: getMetaContent('csrf-token'),
    };

    submitForm();
    axios
      .post(url, params)
      .then(res => {
        submitted();
        this.setState({
          message: '',
          errors: '',
          characters: 500,
          created: true,
          conversationId: res.data.conversation.id,
          conversationInviteId: res.data.conversation_invite_token,
        });
        if (redirectPath) {
          setTimeout(() => {
            window.location = redirectPath;
          }, 3000);
        }
      })
      .catch(err => {
        submitted();
        this.setState({
          errors: err.response && err.response.data,
          message: '',
          characters: 500,
          created: false,
        });
      });
  };

  handleMessageChange = (evt) => {
    evt.preventDefault();
    let charactersLeft = 500 - evt.target.value.length;
    charactersLeft = charactersLeft < 0 ? 0 : charactersLeft;
    this.setState({
      message: evt.target.value,
      characters: charactersLeft,
      created: false,
    });
  };

  handleOpenMessages = evt => {
    evt.preventDefault();

    const { signedIn } = this.props;
    const { conversationId, conversationInviteId } = this.state;
    if (signedIn) {
      window.location = `/conversations/${conversationId}`;
    } else if (conversationInviteId) {
      window.location = `/conversation_invites/${conversationInviteId}`;
    }
  }

  handleRecaptchaChange = () => {
    this.setState({ recaptchaChecked: true });
  }

  render() {
    const { submitting, signedIn } = this.props;
    const {
      conversationInviteId, characters, created, message, recaptchaChecked, errors,
    } = this.state;
    const submitDisabled = submitting || !message.length || (!signedIn && !this.recaptchaRef.current) || (!signedIn && !recaptchaChecked);

    return (
      <div className="container invite-container">
        { !created ? (
          <>
            <h1>Message</h1>
            <hr className="entire-width" />
            {created && <FlashMessage message="Message was successfully sent!" />}
            <form onSubmit={this.handleSubmit}>
              <fieldset>
                <textarea
                  onChange={this.handleMessageChange}
                  value={message}
                  placeholder="Send a nice message."
                  className="general-text-area limit_500"
                  type="text"
                  name="conversation[message]"
                  autoComplete="off"
                  rows="4"
                  id="conversation_message"
                />
                <br />
                <p className="form-help-block">
                  <span id="remain">{characters}</span>
                  {' '}
                  characters remaining
                </p>
              </fieldset>
              <br />
              { !!errors && <p className="error">{errors}</p> }
              { !signedIn && (
                <>
                  <input
                    type="email"
                    name="email"
                    className="general-text-field mb-4"
                    autoComplete="email"
                    placeholder="Email"
                    required
                  />
                  <Recaptcha
                    ref={this.recaptchaRef}
                    sitekey={process.env.RECAPTCHA_SITE_KEY}
                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={this.handleRecaptchaChange}
                  />
                </>
              )}
              <input
                type="submit"
                name="commit"
                value="Send"
                className="button btn-black-white btn-rounded"
                disabled={submitDisabled}
              />
            </form>
          </>
        ) : (
          <>
            <h1>Message</h1>
            <hr className="entire-width" />
            <div className="warning-box">
              <div className="text-center">
                <i className="nookons-check-circle" style={{ color: '#26AE90' }} />
              </div>
              <div className="text-center">Your message was successfully received.</div>
            </div>
            <div className="text-center">
              <div className={`button btn-rounded text-left btn-request ${(signedIn || conversationInviteId) ? '' : 'disabled'}`} style={{ width: '150px' }} onClick={this.handleOpenMessages}>
                <span>Open messages</span>
                <i className="nookons-messages ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

class MessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      submitted: false,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleModalCloseRequest = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;

    return (
      <div>
        <div onClick={this.openModal}>{this.props.children}</div>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
            closeTimeoutMS={150}
            isOpen={this.state.modalIsOpen}
          >
            <div className="modal-content modal-content-dark">
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <MessageContent {...this.props} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

MessageModal.defaultProps = {
  signedIn: true,
};

const mapState = state => ({
  submitting: state.form.submitting,
});

const mapDispatch = dispatch => ({
  submitForm() {
    dispatch(submitForm());
  },
  submitted() {
    dispatch(submitted());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(MessageModal);
