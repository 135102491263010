import React from 'react';
import PropTypes from 'prop-types';

// Props:
// --------------------------------------------------
// btnText: Text for our button on right (Optional) (Default: "Continue")
// disabled: If our button is disabled (Optional) (Default: false)
// handleClick: OnClick for our button (Optional) (Default: undefined)
// shortAddress: Address to display on left (Optional) (Default: '')

const RequestTourFooter = props => {
  const {
    btnText,
    disabled,
    handleClick,
    shortAddress,
  } = props;

  return (
    <div className="request-tour-footer">
      <div className="request-tour-footer-content">
        <div className="d-flex flex-column">
          {(shortAddress !== '')
            && (
            <>
              <div className="tour-uc-notes">
                Request a tour to
              </div>
              <div className="mt-2" style={{ fontSize: '14px' }}>
                {shortAddress}
              </div>
            </>
            )}
        </div>
        {(handleClick !== undefined) && (
        <button
          className={`button btn-transparent-yellow btn-rounded text-left ${disabled ? 'disabled' : ''}`}
          type="submit"
          onClick={handleClick}
          disabled={disabled}
        >
          <span>{btnText}</span>
          <i className="nookons-arrow-right ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
        </button>
        )}
      </div>
    </div>
  );
};

RequestTourFooter.propTypes = {
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  shortAddress: PropTypes.string,
};

RequestTourFooter.defaultProps = {
  btnText: 'Continue',
  disabled: false,
  handleClick: undefined,
  shortAddress: '',
};

export default RequestTourFooter;
