import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RequestTourFooter from 'components/renterForms/RequestTourFooter';

// Props:
// --------------------------------------------------
// acceptApply: Boolean for if current listing accepts quick apply (Optional) (Default: false)
// handleApply: Function for handle Apply onclick (Optional) (Default: Premade Function)
// intialOption: Intial Option for Form Step (Optional) (Default: 0)
// onSubmit: Callback Function on Submit (Required)
// shortAddress: Address to display on bottom (Required)

const TourTypeSelector = props => {
  const {
    acceptApply,
    handleApply,
    initialOption,
    onSubmit,
    shortAddress,
  } = props;

  const TOUR_OPTIONS = {
    IN_PERSON: 1,
    VIRTUAL: 2,
  };

  const [activeLi, setActiveLi] = useState(initialOption);
  const [disabledContinue, setDisabledContinue] = useState(initialOption === 0);

  const handleClick = num => {
    if (num === 1 || num === 2) setDisabledContinue(false);
    setActiveLi(num);
  };

  return (
    <>
      <h3>How do you want to tour?</h3>
      <div className="tour-type-select">
        <div
          className={`tour-type-card mr-3 mb-3 ${activeLi === 1 && 'active'}`}
          onClick={() => handleClick(1)}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              handleClick(TOUR_OPTIONS.IN_PERSON);
            }
          }}
          role="button"
          tabIndex="0"
        >
          <div className="tour-type-text">In-person</div>
          <i className="nookons-group mr-3" />
        </div>
        <div
          className={`tour-type-card ${activeLi === 2 && 'active'}`}
          onClick={() => handleClick(2)}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              handleClick(TOUR_OPTIONS.VIRTUAL);
            }
          }}
          role="button"
          tabIndex="-1"
        >
          <div className="tour-type-text">Virtually</div>
          <i className="nookons-wifi mr-3" />
        </div>
      </div>
      {(acceptApply) && (
        <div className="tour-notes">
          Already toured this apartment?&nbsp;
          <b
            onClick={handleApply}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                handleApply();
              }
            }}
            role="button"
            tabIndex="0"
          >
            Submit an application
          </b>
        </div>
      )}
      <RequestTourFooter
        shortAddress={shortAddress}
        handleClick={() => { onSubmit(activeLi); }}
        disabled={disabledContinue}
      />
    </>
  );
};

TourTypeSelector.propTypes = {
  acceptApply: PropTypes.bool,
  handleApply: PropTypes.func,
  initialOption: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  shortAddress: PropTypes.string.isRequired,
};

TourTypeSelector.defaultProps = {
  acceptApply: false,
  handleApply: () => { console.log('No Apply Function Given'); },
  initialOption: 0,
};

export default TourTypeSelector;
