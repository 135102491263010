import React, { useEffect, useState } from 'react';
import { getParamsFromLink } from 'helpers/util';
import PropTypes from 'prop-types';

const ScheduleTour = props => {
  const {
    listing,
    loading,
    salesAgentID,
  } = props;

  const {
    id,
    status,
  } = listing || {};

  const [listingUrl, setListingUrl] = useState('');

  useEffect(() => {
    if (id !== undefined && salesAgentID !== -1) {
      const params = getParamsFromLink();
      setListingUrl(`/listings/${id}/tour${params['sales-agent'] ? `?sales-agent=${params['sales-agent']}` : ''}`);
    }
  }, [salesAgentID, id]);

  const handleClick = () => {
    if (!loading) {
      window.location.href = listingUrl;
    }
  };

  const handleKeyDown = () => evt => {
    if (evt.key === 'Enter') {
      handleClick();
    }
  };

  if (status === 'Rented') {
    return null;
  }

  return (
    <div className="lead_schedule">
      <div
        onClick={handleClick}
        className="button btn-yellow-black btn-rounded btn-search-bar-size text-left d-flex "
        style={{ justifyContent: 'space-between' }}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <span className="d-flex">Request a tour</span>
        <i className="nookons-arrow-right d-flex ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
      </div>
    </div>
  );
};

ScheduleTour.defaultProps = {
  salesAgentID: -1,
  loading: false,
};

ScheduleTour.propTypes = {
  loading: PropTypes.bool,
  listing: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    status: PropTypes.string,
  }).isRequired,
  salesAgentID: PropTypes.number,
};

export default ScheduleTour;
