import React from 'react'
import axios from 'axios'
import { getMetaContent } from '../../helpers/util'
import RentalAppModal from '../modal/LeaseDashboardRentalAppModal'

const Apply = (props) => {
  const { handleStartApplication, feedback, current_rental_app, lease_client_type } = props

  return (
    <div className="container">
      <h1>{lease_client_type === 'guarantor' ? `Guarantee` : `Apply`}</h1>
      <p>
        {lease_client_type === 'guarantor'
          ? `NYC apartments typically require a guarantors income to be 80x
        the monthly rent when the guarantor is providing financial
        support to pay rent, or may be called on to cover rent.
        If you are not currently employed, showing assets instead of income
        is fine. If you have any questions, feel free to reach out.`
          : `OK! Time to apply. We need to verify your identity, prove your income,
        check your credit score and run a background check.
        If you have any questions, feel free to reach out.`}
      </p>
      {current_rental_app ? (
        <RentalAppModal className="d-inline-block" {...props}>
          <button className="button btn-rounded btn-transparent-yellow">Start Application</button>
        </RentalAppModal>
      ) : (
        <button onClick={handleStartApplication} className="button btn-rounded btn-transparent-yellow">
          Start Application
        </button>
      )}
      <a
        className="button btn-rounded btn-transparent"
        href={`/support?feedback[message_context]=${feedback}`}
        target="_blank"
      >
        Help
      </a>
    </div>
  )
}

export default class JumbotronRentalApp extends React.Component {
  handleStartApplication = (evt) => {
    const { lease_client, setMessage, updateRentalApplication } = this.props

    evt.preventDefault()
    axios
      .post(`/rental_applications.json?lease_client_id=${lease_client.id}`, {
        authenticity_token: getMetaContent('csrf-token'),
      })
      .then((res) => {
        if (res.data.token) {
          updateRentalApplication(res.data)
        }
      })
      .catch((err) => {
        console.dir(err)
        setMessage('Something went wrong. Please refresh the page.')
      })
  }

  render() {
    return (
      <div>
        <Apply {...this.props} handleStartApplication={this.handleStartApplication} />
      </div>
    )
  }
}
