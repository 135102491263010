import React, { useEffect, useState } from 'react';
import strftime from 'strftime';
import axios from 'axios';
import { useProContext } from 'pro/ProStore';
import ConversationModal from 'components/modal/ConversationModal';
import { keysToCamel } from 'pro/util/inflector';

const ConversationRow = props => {
  const {
    conversation: {
      hasUnreadMessages,
      messages,
      participants,
      updatedAt,
    },
  } = props;

  const otherParticipant = participants.find(p => p.otherParticipant === true);
  const recentMessage = messages[messages.length - 1].message;

  return (
    <div className="row conversation-row" style={{ borderTop: 'solid 1px #404040' }}>
      <div className="col-3">
        <img
          className="img-fluid rounded-circle"
          src={otherParticipant.image}
        />
      </div>
      <div className="col-7" style={{ borderLeft: 'none', textAlign: 'left', padding: '20px' }}>
        <div className="notification-name">
          <span>
            {otherParticipant.name}
          </span>
          {hasUnreadMessages && <span className="unread-message-dot" />}
        </div>
        <div className="notification-message">
          {recentMessage}
        </div>
        <div className="notification-updated-at">
          {strftime('%H:%M%p | %B %d, %Y', new Date(updatedAt))}
        </div>
      </div>
      <div className="col-1">
        <div style={{ lineHeight: '130px', cursor: 'pointer' }}>
          <i className="nookons-chevron-right nklyn-3x" />
        </div>
      </div>
    </div>
  );
};

const RoomConversations = (props) => {
  const { roomId } = props;

  const { apiToken } = useProContext();
  const [conversations, setConversations] = useState([]);
  const cancelToken = axios.CancelToken.source().token;

  const fetchConversation = () => {
    if (roomId) {
      const url = `/api/v2/user.rooms.conversations?room_id=${roomId}`;
      axios
        .get(url, { headers: { API_TOKEN: apiToken, cancelToken } })
        .then(result => result.data.conversations)
        .then(conversationData => keysToCamel(conversationData))
        .then(conversationData => {
          if (conversationData.length > 0) {
            setConversations(conversationData);
          }
        });
    }
  };

  useEffect(() => {
    fetchConversation();
  }, [roomId]);

  return (
    <>
      <div className="apartment-container-header">Conversations</div>
      <div className="apartment-container-content">
        {conversations.length ? conversations.map(conversation => (
          <div key={conversation.id}>
            <ConversationModal conversation={conversation} fetchConversation={fetchConversation}>
              <ConversationRow conversation={conversation} />
            </ConversationModal>
          </div>
        )) : (
          <div className="container">
            <div className="mx-auto text-center">Someone will reach out soon!</div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoomConversations;
