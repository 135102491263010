import React, { useState, useEffect, useRef } from 'react';
import strftime from 'strftime';
import axios from 'axios';
import Modal from './Modal';

/*
 * Note:
 *
 * As far as I can tell, this code is only being used by Nooklyn Pro. It should
 * really be moved into that section of the code base and refactored. If it is
 * a shared component, then it should be more modular wherein it's in charge
 * of fetching all of the data with conversationId as the only property necessary
 * to pass in.
 *
 * There should ideally also be a periodical poll of the server to fetch new
 * messages.
 *
 */

const ModalContent = (props) => {
  const { conversation, fetchConversation } = props;
  const [message, setMessage] = useState('');
  const containerRef = useRef(null);

  const sendMessage = (evt) => {
    evt.preventDefault();
    if (!message.trim()) return;

    axios
      .post('/api/v2/conversations.create.message', {
        conversation_id: conversation.id,
        message,
      }, {
        headers: { API_TOKEN: localStorage.getItem('apiToken') },
      })
      .then(() => {
        setMessage('');
        fetchConversation();
      });
  };

  useEffect(() => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, []);

  return (
    <div className="conversation-show-container m-0">
      <div
        className="conversation-messages-side m-4 border-0"
        id="conversation-messages-side-container"
        ref={containerRef}
      >
        {conversation.messages.length
          && conversation.messages.map(message => (
            <div className="conversation-message-row" key={message.id}>
              {!conversation.participants.find(p => +p.id === +message.agentId).otherParticipant ? (
                <div className="row">
                  <div className="col-9">
                    <div className="conversation-message-bubble-sender">{message.message}</div>
                  </div>
                  <div className="col-3">
                    <img
                      src={conversation.participants.find(p => p.otherParticipant === false).image}
                      className="rounded-circle img-fluid img-human img-small mx-auto"
                    />
                    <br />
                    <h5>
                      {strftime('%b %e, %Y', new Date(message.createdAt))}
                      <br />
                      {strftime('%I:%M%P', new Date(message.createdAt))}
                    </h5>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-3">
                    <img
                      src={conversation.participants.find(p => p.otherParticipant === true).image}
                      className="rounded-circle img-fluid img-human img-small mx-auto"
                    />
                    <br />
                    <h5>
                      {strftime('%b %e, %Y', new Date(message.createdAt))}
                      <br />
                      {strftime('%I:%M%P', new Date(message.createdAt))}
                    </h5>
                  </div>
                  <div className="col-9">
                    <div className="conversation-message-bubble-receiver">{message.message}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        <div id="message-bottom" />
      </div>
      <form onSubmit={sendMessage}>
        <div className="container-fluid">
          <div className="conversation-write-message-area row">
            <div className="col-8">
              <input
                type="text-area"
                className="general-text-area-dark limit_500"
                autoComplete="off"
                value={message}
                onChange={evt => setMessage(evt.target.value)}
              />
            </div>
            <div className="col-4 conversation-message-user-area">
              <input
                type="submit"
                value="Send"
                className="button btn-yellow-black btn-rounded btn-block"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default class ConversationModal extends React.Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
    this.markAsRead();
  };

  closeModal = () => {
    const { fetchConversation } = this.props;

    this.setState({ modalIsOpen: false });
    fetchConversation();
  };

  markAsRead = () => {
    const { conversation } = this.props;

    axios
      .post('/api/v2/conversations.mark_as_read', {
        conversation_id: conversation.id,
      }, {
        headers: { API_TOKEN: localStorage.getItem('apiToken') },
      });
  };

  render() {
    const { className, children } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className={className}>
        <div onClick={this.openModal}>{children}</div>
        <Modal
          className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
          closeTimeoutMS={150}
          isOpen={modalIsOpen}
        >
          <div className="modal-content">
            <button type="button" className="close" onClick={this.closeModal}>
              <i className="nookons-close-circle-fill" style={{ color: '#fff' }} />
            </button>
            <ModalContent {...this.props} handleModalCloseRequest={this.closeModal} />
          </div>
        </Modal>
      </div>
    );
  }
}
