import React from 'react';
import PropTypes from 'prop-types';

// Props:
// --------------------------------------------------
// onSubmit: Callback Function on Submit (Required)

const WarningConfirmation = props => {
  const {
    onSubmit,
  } = props;

  const submitWarning = () => {
    onSubmit(true);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <>
      <h3>Warning!</h3>
      <div className="tour-notes">Heads Up! This is a listing from a user on Nooklyn.</div>
      <div className="tour-notes">We've tried our best to vet and review this listing but</div>
      <div className="tour-notes">please use your best judgement, especially before sending funds.</div>
      <br />
      <div
        className="button btn-transparent btn-rounded text-left btn-request"
        role="button"
        label="Accept Warning Message"
        onKeyPress={handleKeyPress}
        onClick={submitWarning}
        tabIndex={0}
      >
        <span>Accept</span>
        <i className="nookons-arrow-right ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
      </div>
    </>
  );
};

WarningConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WarningConfirmation;
