import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import strftime from 'strftime';
import RequestTourFooter from 'components/renterForms/RequestTourFooter';

// Props:
// --------------------------------------------------
// intialDate: Inital Time for Form Step (Optional) (Default: undefined)
// intialTime: Intial Option for Form Step (Optional) (Default: undefined)
// onSubmit: Callback Function on Submit (Required)
// shortAddress: Address to display on bottom (Required)

const DateSelection = props => {
  const {
    initialDate,
    initialTime,
    onSubmit,
    shortAddress,
  } = props;

  const [disabledContinue, setDisabledContinue] = useState(true);
  const [preferredDays, setPreferredDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedTime, setSelectedTime] = useState(initialTime);
  const preferredTime = ['Morning', 'Afternoon', 'Evening'];

  useEffect(() => {
    const today = new Date();
    const oneDayLater = new Date(new Date().setDate(new Date().getDate() + 1));
    const twoDayLater = new Date(new Date().setDate(new Date().getDate() + 2));
    const threeDayLater = new Date(new Date().setDate(new Date().getDate() + 3));
    const dates = [today, oneDayLater, twoDayLater, threeDayLater].map((date, idx) => {
      let weekday;
      switch (idx) {
        case 0:
          weekday = 'Today';
          break;
        case 1:
          weekday = 'Tomorrow';
          break;
        default:
          weekday = strftime('%A', date);
      }
      return ({
        weekday,
        date,
      });
    });
    setPreferredDays(dates);
  }, []);

  useEffect(() => {
    if (disabledContinue && selectedTime !== undefined && selectedDate !== undefined) {
      setDisabledContinue(false);
    }
  }, [selectedDate, selectedTime]);

  return (
    <>
      <h3>When do you prefer to tour the apartment?</h3>
      <div className="tour-notes">You can skip this step and decide later.</div>
      <div className="mt-5" style={{ fontSize: '14px' }}>Preferred day</div>
      <div className="preferred-date-select">
        { preferredDays && (
          preferredDays.map(day => (
            <div className={`preferred-date-card ${selectedDate == day.date ? 'active' : ''}`} key={day.date} onClick={() => setSelectedDate(day.date)}>
              <div className="preferred-date-card-top">
                <div className="mx-auto">{day.weekday}</div>
              </div>
              <div className="preferred-date-card-bottom">
                <div className="mx-auto">{strftime('%B %e', day.date)}</div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="mt-3" style={{ fontSize: '14px' }}>Preferred time</div>
      <div className="preferred-date-select">
        {
          preferredTime.map(time => (
            <div className={`preferred-date-card flex-row justify-content-between align-items-center ${selectedTime == time ? 'active' : ''}`} onClick={() => setSelectedTime(time)} key={time}>
              <span className="pl-3">{time}</span>
              <img alt={time} src={`${require(`images/icons/svg/${time.toLowerCase()}.svg`)}`} className="pr-3" />
            </div>
          ))
        }
      </div>
      <RequestTourFooter
        shortAddress={shortAddress}
        handleClick={() => { onSubmit(selectedDate, selectedTime); }}
        disabled={disabledContinue}
      />
    </>
  );
};

DateSelection.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  initialTime: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  shortAddress: PropTypes.string.isRequired,
};

DateSelection.defaultProps = {
  initialDate: undefined,
  initialTime: undefined,
};

export default DateSelection;
