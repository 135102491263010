import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import missing from 'images/logo/white-on-black-square.png'

import s from './Annotation.module.css'

function Annotation(props) {
  const { marker, isMulti } = props

  return (
    <a href={marker.url} rel="noreferrer" target="_blank">
      <div className={clsx(s.root, isMulti && s.multi)}>
        {(marker.image_url || marker.imageUrl) && (
          <div className={s.imageWrapper}>
            <img
              src={marker.image_url || marker.imageUrl}
              className={s.image}
              alt={marker.subtitle}
              onError={e => {
                e.target.onerror = null
                e.target.src = missing
              }}
            />
          </div>
        )}

        <div className={s.desc}>
          {marker.title}
          <br /> {marker.subtitle}
        </div>
      </div>
    </a>
  )
}

Annotation.defaultProps = {
  isMulti: false,
  marker: null,
}

Annotation.propTypes = {
  isMulti: PropTypes.bool,
  marker: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    url: PropTypes.string,
    image_url: PropTypes.string,
    imageUrl: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    id: PropTypes.number,
  }),
}

export default Annotation
