import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getParamsFromLink } from 'helpers/util';

const TourButton = props => {
  const { propertyId, salesAgent, signedIn } = props;

  const [listingUrl, setListingUrl] = useState('');

  useEffect(() => {
    if (propertyId !== undefined ) {
      const params = getParamsFromLink();
      setListingUrl(`/listings/${propertyId}/tour${params['sales-agent'] ? `?sales-agent=${params['sales-agent']}` : ''}`);
    }
  }, [salesAgent, propertyId]);

  const handleClick = () => {
    window.location.href = listingUrl;
  };

  const handleKeyDown = () => evt => {
    if (evt.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      style={{ padding: '7px 15px', border: '0' }}
      className="col-8"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      Request a tour
      <i className="nookons-arrow-right float-right" id="nklyn-listing-tour-img" style={{ color: '#FFC03A', fontWeight: '500', marginTop: '2px' }} />
    </div>
  );
};

TourButton.propTypes = {
  propertyId: PropTypes.number.isRequired,
  salesAgent: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  signedIn: PropTypes.bool,
};

TourButton.defaultProps = {
  signedIn: false,
};

export default TourButton;
